import { errorFromResponse } from "../fetcher";
import { errorToString } from "../errors";

type Props = {
  formData: {
    fromDate: string;
    toDate: string;
  };
  subscriptionId: string;
  publicationDomain: string;
};

type PauseDeliveryResponse = {
  status: "success" | "already_paused" | "error";
  error?: string;
};

type PauseDeliveryResult = {
  data?: PauseDeliveryResponse;
  error?: Error;
};

const isValidData = (response: unknown): response is PauseDeliveryResponse => {
  const r = response as PauseDeliveryResponse;
  return ["success", "already_paused", "error"].includes(r.status);
};

export const pauseDelivery = async ({
  formData,
  subscriptionId,
  publicationDomain,
}: Props): Promise<PauseDeliveryResult> => {
  const url = `/api/vorwerk/subscription/${subscriptionId}/delivery/pause`;

  const fetchOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      fromDate: formData.fromDate,
      toDate: formData.toDate,
      publicationDomain,
    }),
  };

  try {
    const response = await fetch(url, fetchOptions);

    if (!response.ok) {
      throw await errorFromResponse(response);
    }

    const data = await response.json();
    if (isValidData(data)) {
      return { data };
    }

    throw Error(
      `Pause delivery returned unexpected data: ${JSON.stringify(data)}`,
    );
  } catch (error) {
    return {
      error: Error(`Pause delivery failed. Error: ${errorToString(error)}`),
    };
  }
};
