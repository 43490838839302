import React from "react";
import { styled } from "styled-components";
import { Link, useOutletContext } from "react-router";

import FamilyInfo from "./familyInfo";

import { ButtonContainer } from "@/components/ButtonContainer";
import { Sheet } from "@/components/Sheet";
import { useTranslate } from "@/i18n";
import { Family } from "@/services/mercury/families/internal";
import PageHeader from "@/components/PageHeader";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute/subscriptionRoute";
import routes from "@/routes/routes";
import { BrickButton } from "@/components/BrickButton/brickButton";

type Props = {
  families: Family[];
  handleNext: () => void;
};

const CancelFamilyInfo: React.ComponentType<Props> = (props) => {
  const { families, handleNext } = props;
  const t = useTranslate(messages);
  const { user } = useOutletContext<SubscriptionOutlet>();

  return (
    <>
      <PageHeader>{t("title")}</PageHeader>
      <Sheet $primary>
        <Heading>{t("heading")}</Heading>
        {families.map(
          (family) =>
            family.members.length > 1 && (
              <FamilyInfo
                key={family.uuid}
                family={family}
                filterUserUUID={user.uuid}
              />
            ),
        )}
        <Note>{t("note")}</Note>
        <ButtonContainer>
          <BrickButton
            as="button"
            label={t("submit")}
            version="primary"
            width="normal"
            onClick={handleNext}
          />
          <Link to={routes.myPage.path()}>
            <BrickButton as="span" label={t("cancel")} version="outlined" />
          </Link>
        </ButtonContainer>
      </Sheet>
    </>
  );
};

const Heading = styled.p`
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 20px;
`;

const Note = styled.p`
  margin-top: 60px;
`;

export default CancelFamilyInfo;

const messages = {
  title: {
    nb: "Si opp abonnementet",
    nn: "Sei opp abonnementet",
  },
  heading: {
    nb: "Du deler abonnementet ditt med",
    nn: "Du deler abonnementet ditt med",
  },
  note: {
    nb: "Husk at de du har delt abonnementet ditt med også vil miste tilgangen hvis du sier opp.",
    nn: "Hugs at dei du har delt abonnementet ditt med også vil miste tilgangen dersom du seier opp.",
  },
  submit: {
    nb: "Fortsett oppsigelsen",
    nn: "Hald fram med å seie opp avisa",
  },
  cancel: {
    nb: "Behold abonnementet",
    nn: "Behald abonnementet",
  },
};
