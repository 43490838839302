import React, { useContext } from "react";
import { useOutletContext } from "react-router";

import ActivateSubscription from "./activateSubscription";
import SubscriptionCancelled from "./subscriptionCancelled";
import PlussaltUpgradeAvailable from "./plussaltUpgradeAvailable";
import AutoInvoicePromo from "./autoInvoicePromo";

import { includesPlussalt } from "@/utils/productFeatures";
import AppContext from "@/contexts/appContext";
import { AppOutlet } from "@/components/AppRoute/appRoute";

const PrioritizedActions: React.FC = () => {
  const { subscriptions, site } = useContext(AppContext);
  const { user } = useOutletContext<AppOutlet>();

  const cancelledSubscriptions = subscriptions.data.filter(
    (s) => !!s.pendingCancellationDate,
  );
  const nonPlussaltSubscription = subscriptions.data.find(
    (s) => !includesPlussalt(s.product.productFeatures),
  );
  const subscription = subscriptions.data[0];

  const showActivateSub = !subscription && site.onboardingMode;

  const showPlussaltUpgrade =
    nonPlussaltSubscription && site.participatesInPlussalt;

  if (showActivateSub) {
    return <ActivateSubscription />;
  }

  if (cancelledSubscriptions.length > 0) {
    return (
      <>
        {cancelledSubscriptions.map((s) => (
          <SubscriptionCancelled key={s.id} subscription={s} />
        ))}
      </>
    );
  }

  if (user.extraData.showAutoInvoicePromo && subscription) {
    return <AutoInvoicePromo subscription={subscription} />;
  }

  if (showPlussaltUpgrade) {
    return <PlussaltUpgradeAvailable subscription={nonPlussaltSubscription} />;
  }

  return null;
};

export default PrioritizedActions;
