import React, { type JSX } from "react";
import { PaymentMethod } from "@amedia/node-clients-abo";
import { styled } from "styled-components";

import AutoInvoice from "./paymentMethods/autoInvoice";
import CreditCard from "./paymentMethods/creditCard";
import CustomerService from "./paymentMethods/customerService";

import { useTranslate } from "@/i18n";
import { Sheet } from "@/components/Sheet";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { PaymentMethods } from "@/fetch/payment/helpers";

type Props = {
  subscription: Subscription;
  paymentMethod: PaymentMethod;
};

const ChangePaymentProvider: React.FC<Props> = ({
  subscription,
  paymentMethod,
}) => {
  const t = useTranslate(messages);

  const paymentMethods = paymentMethod.availablePaymentMethods
    .map((available) => available.method)
    .filter((available) =>
      toSupportedPaymentMethods(paymentMethod.method).some(
        (supported) => available === supported,
      ),
    )
    .reduce((acc, current) => {
      const component = toPaymentMethodComponent(subscription, current);
      return component ? [...acc, component] : acc;
    }, [] as JSX.Element[]);

  return (
    <Sheet>
      <h2>{t("changePaymentMethod")}</h2>
      <Wrapper>
        {paymentMethods.length > 0 ? paymentMethods : <CustomerService />}
      </Wrapper>
    </Sheet>
  );
};

const toSupportedPaymentMethods = (currentPaymentMethod: string) => {
  switch (currentPaymentMethod) {
    case PaymentMethods.CREDIT_CARD:
      return [PaymentMethods.AVTALEGIRO];
    case PaymentMethods.BANKGIRO:
      return [PaymentMethods.AVTALEGIRO, PaymentMethods.CREDIT_CARD];
    case PaymentMethods.EFAKTURA:
      return [PaymentMethods.AVTALEGIRO, PaymentMethods.CREDIT_CARD];
    default:
      return [];
  }
};

const toPaymentMethodComponent = (
  subscription: Subscription,
  availablePaymentMethod: string,
) => {
  switch (availablePaymentMethod) {
    case PaymentMethods.AVTALEGIRO:
      return (
        <AutoInvoice key={availablePaymentMethod} subscription={subscription} />
      );
    case PaymentMethods.CREDIT_CARD:
      return (
        <CreditCard
          key={availablePaymentMethod}
          subscriptionId={subscription.id}
        />
      );
    default:
      return null;
  }
};

export default ChangePaymentProvider;

const Wrapper = styled.div`
  > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const messages = {
  changePaymentMethod: {
    nb: "Bytt betalingsmåte",
    nn: "Byt betalingsmåte",
  },
};
