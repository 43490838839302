import type { FetcherError as FetcherErr } from "@amedia/node-clients-abo";

export type FetcherError = FetcherErr;

export const isNetworkError = (e: unknown): boolean => {
  return isClientNetworkError(e) || (e as FetcherErr)?.type === "CLIENT";
};

type HttpErr = FetcherErr & {
  type: "HTTP";
  status: number;
};

export const isHttpError = (e: unknown): e is HttpErr =>
  (e as HttpErr)?.type === "HTTP" && typeof (e as HttpErr).status === "number";
export class HttpError extends Error {
  status?: number;
}

export class ClientError extends Error {}

export class DecodingError extends Error {
  decoderReport?: string[];
}

export const isClientNetworkError = (error: unknown): error is TypeError => {
  const networkErrors = [
    "Failed to fetch",
    "NetworkError when attempting to fetch resource.",
    "Network request failed",
    "Load failed",
  ];

  return (
    (error instanceof TypeError &&
      networkErrors.some((n) => error.message.startsWith(n))) ||
    error instanceof ClientError
  );
};

export const errorToString = (err: unknown) => {
  if (err instanceof Error) {
    return `${err.message}. ${err.cause ? `Cause: ${err.cause}` : ""}`;
  }

  if (err instanceof Object && Object.keys(err).length > 0) {
    return JSON.stringify(err);
  }

  if (typeof err === "string") {
    return err;
  }
  return `Uknown error: ${err}`;
};
