import React, { useContext } from "react";
import { useOutletContext, useParams } from "react-router";

import Permanent from "./components/permanent";
import Temporary from "./components/temporary";

import PageHeader from "@/components/PageHeader";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute/subscriptionRoute";
import SubscriptionTypeNotSupported from "@/components/SubscriptionTypeNotSupported/subscriptionTypeNotSupported";
import AppContext from "@/contexts/appContext";
import { useTranslate } from "@/i18n";
import { log } from "@/logging/logger";
import routes from "@/routes/routes";
import { pageTitle } from "@/utils/page";
import { includesPaperDelivery } from "@/utils/productFeatures";
import BreadCrumbs from "@/components/Breadcrumbs";

export enum AddressChangeSlug {
  Temporary = "midlertidig",
  Permanent = "permanent",
}

export type AddressChangeType = `${AddressChangeSlug}`;

const isValidChangeType = (type: string): type is AddressChangeType =>
  Object.values(AddressChangeSlug).includes(type as AddressChangeSlug);

const ChangeAddressPage: React.FC = () => {
  const t = useTranslate(messages);
  const { changeType } = useParams();
  const context = useContext(AppContext);
  const { locale, name } = context.site;
  const { subscription } = useOutletContext<SubscriptionOutlet>();

  if (!changeType) {
    log.error(`Missing changeType`);
    return <SubscriptionTypeNotSupported subscription={subscription} />;
  }

  if (!isValidChangeType(changeType)) {
    log.error(`Unknown changeType: ${changeType}`);
    return <SubscriptionTypeNotSupported subscription={subscription} />;
  }

  // Temporary address change not available to digital subscriptions
  if (
    !includesPaperDelivery(subscription.product.productFeatures) &&
    changeType === AddressChangeSlug.Temporary
  ) {
    return <SubscriptionTypeNotSupported subscription={subscription} />;
  }

  const texts: Record<string, { title: string; header: string }> = {
    midlertidig: {
      title: "midlertidig",
      header: t("headerTemporary"),
    },
    permanent: {
      title: "permanent",
      header: t("headerPermanent"),
    },
  };

  const typeTitle = texts[changeType].title || "";
  const title = `${routes.changeAddress.title[locale]} ${typeTitle}`;
  pageTitle.set(pageTitle.create(name.short, title));

  const ChangeMap = {
    [AddressChangeSlug.Permanent]: <Permanent subscription={subscription} />,
    [AddressChangeSlug.Temporary]: <Temporary subscription={subscription} />,
  };

  return (
    <>
      <BreadCrumbs>{title}</BreadCrumbs>
      <PageHeader>{texts[changeType].header}</PageHeader>
      {ChangeMap[changeType]}
    </>
  );
};

export default ChangeAddressPage;

const messages = {
  headerTemporary: {
    nb: "Jeg skal reise bort",
    nn: "Eg skal reisa bort",
  },
  headerPermanent: {
    nb: "Leveringsendringer",
    nn: "Leveringsendringar",
  },
};
