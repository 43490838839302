import React, { useMemo, useState } from "react";
import { styled } from "styled-components";
import { Link, useOutletContext } from "react-router";

import { ButtonContainer } from "@/components/ButtonContainer";
import { Sheet } from "@/components/Sheet";
import { InputField } from "@/components/InputField";
import PageHeader from "@/components/PageHeader";
import { Translation, useTranslate } from "@/i18n";
import RadioButtonGroup from "@/components/RadioButtonGroup";
import Heading from "@/components/Heading";
import { includesPaperDelivery } from "@/utils/productFeatures";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute/subscriptionRoute";
import routes from "@/routes/routes";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { BrickButton } from "@/components/BrickButton/brickButton";

type Choices = keyof typeof choices;
const printOnlyChoices: Array<Choices> = ["WEAL", "FR"];
const campaignOnlyChoices: Array<Choices> = ["WEEN"];

const useFilteredChoices = (subscription: Subscription, inCampaign: boolean) =>
  useMemo(() => {
    if (includesPaperDelivery(subscription.product.productFeatures)) {
      return choices;
    }
    const entries = Object.entries as <T>(
      o: T,
    ) => Array<[Extract<keyof T, string>, T[keyof T]]>;

    return entries(choices).reduce<Record<string, Translation>>(
      (acc, [key, value]) => {
        if (!printOnlyChoices.includes(key)) {
          // Only add campaign choice for campaign-customer
          if (inCampaign) {
            acc[key] = value;
          } else if (!inCampaign && !campaignOnlyChoices.includes(key)) {
            acc[key] = value;
          }
        }
        return acc;
      },
      {},
    );
  }, [subscription, inCampaign]);

type Props = {
  handleNext: ({
    changeDate,
    reasonCode,
    reasonText,
  }: {
    changeDate: string;
    reasonCode: Choices;
    reasonText: string;
  }) => void;
};

const CancelReason: React.FC<Props> = ({ handleNext }) => {
  const t = useTranslate({ ...messages, ...choices });
  const [reasonCode, setReasonCode] = useState<Choices>();
  const { subscription, user } = useOutletContext<SubscriptionOutlet>();

  const filteredChoices = useFilteredChoices(
    subscription,
    user.extraData.inCampaign,
  );

  const handleSubmit = () => {
    if (reasonCode) {
      const singleDate =
        subscription.earliestCancellationDate ===
        subscription.lastCancellationDate;

      handleNext({
        changeDate: singleDate
          ? subscription.earliestCancellationDate || ""
          : subscription.defaultCancellationDate || "",
        reasonCode,
        reasonText: choices[reasonCode]["nb"],
      });
    }
  };

  return (
    <>
      <PageHeader>{t("title")}</PageHeader>
      <Sheet $primary>
        <Heading>{t("helpUs")}</Heading>
        <p>{t("cancelReason")}</p>
        <CappedInputField>
          <RadioButtonGroup
            selected={reasonCode}
            groupName="reasonCode"
            callBack={(value) => setReasonCode(value as Choices)}
            options={Object.entries(filteredChoices).map(([code]) => ({
              value: code,
              labelComponent: <label>{t(code as Choices)}</label>,
            }))}
          />
        </CappedInputField>
        <ButtonContainer>
          <BrickButton
            as="button"
            label={t("submit")}
            version="primary"
            disabled={!reasonCode}
            onClick={handleSubmit}
            width="normal"
          />
          <Link to={routes.myPage.path()}>
            <BrickButton as="span" label={t("cancel")} version="outlined" />
          </Link>
        </ButtonContainer>
      </Sheet>
    </>
  );
};

const CappedInputField = styled(InputField)`
  max-width: 324px;
`;

export default CancelReason;

const messages = {
  title: {
    nb: "Si opp abonnementet",
    nn: "Sei opp abonnementet",
  },
  cancelReason: {
    nb: "Velg årsaken til oppsigelsen din",
    nn: "Grunngje årsaka til at du seier opp avisa",
  },
  submit: {
    nb: "Fortsett oppsigelsen",
    nn: "Hald fram med å seie opp avisa",
  },
  cancel: {
    nb: "Behold abonnementet",
    nn: "Behald abonnementet",
  },
  helpUs: {
    nb: "Hjelp oss å bli bedre",
    nn: "Hjelp oss å bli betre",
  },
};

const choices = {
  WEEN: {
    nb: "Ville lese en artikkel/se en kamp",
    nn: "Ville lese ein artikkel/se ein kamp",
  },
  WEPA: {
    nb: "Jeg trenger en pause",
    nn: "Eg treng ein pause",
  },
  WELE: {
    nb: "Har en annen tilgang til avisen",
    nn: "Har ein anna tilgang til avisa",
  },
  WEMV: {
    nb: "Leser ikke avisen nok",
    nn: "Les ikkje avisa nok",
  },
  WEPR: {
    nb: "Pris / økonomi",
    nn: "Pris / økonomi",
  },
  WEFL: {
    nb: "Jeg har flyttet",
    nn: "Eg har flytta",
  },
  WERE: {
    nb: "Innholdet er ikke relevant",
    nn: "Innhaldet er ikkje relevant",
  },
  WEAL: {
    nb: "Problemer med levering",
    nn: "Problem med levering",
  },
  FR: {
    nb: "Kvalitet/frekvens på papirprodukt",
    nn: "Kvalitet/frekvens på papirprodukt",
  },
  SW: {
    nb: "Ønsker ikke å oppgi årsak",
    nn: "Ønskjer ikkje å oppgje årsak",
  },
};
