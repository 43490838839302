import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Navigate, useOutletContext } from "react-router";

import CallbackNavigate from "./callbackNavigate";

import AppContext from "@/contexts/appContext";
import Spinner from "@/components/Spinner";
import { log } from "@/logging/logger";
import routes from "@/routes/routes";
import { netsEasyResumeReplaceCard } from "@/fetch/payment/creditCard";
import { UpdatePaymentStatus } from "@/fetch/payment/helpers";
import { useSubscriptionFetcher } from "@/fetch/subscription/useSubscriptionFetcher";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute";

const NetsEasyReplaceCard: React.FC = () => {
  const context = useContext(AppContext);
  const { locale, domain } = context.site;
  const { subscription } = useOutletContext<SubscriptionOutlet>();

  const urlParams = new URLSearchParams(window.location.search);
  const infosoftOrderRef =
    urlParams.get("InfosoftOrderReference") ||
    urlParams.get("infosoftOrderReference"); // For some reason, (my?) chrome changes the case of the query param
  const [status, setStatus] = useState<UpdatePaymentStatus>();
  const [doCallbackNavigate, setDoCallbackNavigate] = useState<boolean>(false);
  const { forceRefetch } = useSubscriptionFetcher(locale, domain);

  const previousPaymentMethodId = useMemo(
    () => subscription.paymentMethodId,

    [],
  );

  const completeCardChange = useCallback(async () => {
    if (infosoftOrderRef && !status) {
      const resumeCardResponse = await netsEasyResumeReplaceCard({
        infosoftOrderRef,
        subscriptionId: subscription.id,
      });
      if (resumeCardResponse.status === "error") {
        log.error("Failed to resume replace card", resumeCardResponse.error);
        setStatus(UpdatePaymentStatus.ERROR);
      } else if (resumeCardResponse.status === "success") {
        setStatus(UpdatePaymentStatus.SUCCESS);
      }
    }
  }, [infosoftOrderRef, status, subscription.id]);

  useEffect(() => {
    completeCardChange();
  }, [completeCardChange]);

  useEffect(() => {
    if (status) {
      const subscriptionIsUpdated =
        previousPaymentMethodId !== subscription.paymentMethodId;
      if (
        !doCallbackNavigate &&
        status === UpdatePaymentStatus.SUCCESS &&
        subscriptionIsUpdated
      ) {
        setDoCallbackNavigate(true);
      } else if (status === UpdatePaymentStatus.ERROR) {
        setDoCallbackNavigate(true);
      }
      if (!doCallbackNavigate && !subscriptionIsUpdated) {
        // This happens twice
        forceRefetch();
      }
    }
  }, [
    forceRefetch,
    doCallbackNavigate,
    previousPaymentMethodId,
    status,
    subscription.paymentMethodId,
  ]);

  if (!infosoftOrderRef) {
    log.error(`NetsEasyReplaceCard, missing param infosoftOrderRef`);
    return <Navigate to={routes.error.path()} replace={true} />;
  }

  if (doCallbackNavigate && status) {
    return <CallbackNavigate type="replaceCard" status={status} />;
  }
  return <Spinner />;
};

export default NetsEasyReplaceCard;
