import { PaywallUnlockRequest } from "@amedia/user";

import { log } from "@/logging/logger";

const delay = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

// shamo endpoint handled responses
// activation success: {status: "success", description: "subscription_found" }

type Success = {
  status: "success";
  detail: "subscription_activated";
};

type Failure = {
  status: "failure";
};

type Uninitialized = {
  status: "uninitialized";
};

export type ActivationStatus = Success | Failure | Uninitialized;
export type ActivationPromise = Promise<ActivationStatus>;

export const activateSubscription = async (
  publicationDomain: string,
): ActivationPromise => {
  try {
    const response = await fetch(
      `/api/shamo/v2/access/activate?service_provider=${publicationDomain}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      const responseIsJson = response.headers
        .get("content-type")
        ?.includes("application/json");

      const description = responseIsJson
        ? await response.json().then((json) => json.description)
        : undefined;

      throw new Error(
        `Failed to activate subscription. Status code: [${response.status}]. ${description ? `Description: [${description}]` : ""}`,
      );
    }

    const responseJson = await response.json();

    if (responseJson.status === "success") {
      if (responseJson.description === "subscription_found") {
        await delay(1500); // allow systems to sync (hopefully)
        const accessFeatures = ["pluss"]; // pluss is the most basic access feature
        const hasAccess = await new PaywallUnlockRequest(accessFeatures)
          .tryUnlock()
          .then(({ hasAccess }) => hasAccess)
          .catch((err) => {
            throw new Error("Failed to unlock after activation", {
              cause: err,
            });
          });

        if (hasAccess) {
          log.info("User successfully activated subscription");
          return { status: "success", detail: "subscription_activated" };
        }

        throw new Error(
          `Activation returned [${response.status}], but subsequent tryUnlock for user does not have access`,
        );
      } else {
        throw new Error(
          `Activation returned [${response.status}], but with an unhandled description [${responseJson.description}]`,
        );
      }
    }

    throw new Error(
      `Unhandled response from server. [${response.status} ${response.statusText}] ${JSON.stringify(responseJson)}`,
    );
  } catch (error) {
    const err = error instanceof Error ? error : "Unknown error";
    log.error(`Failed to activate subscription: ${err}`, error);
    return { status: "failure" };
  }
};
