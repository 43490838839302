import { errorToString } from "../errors";
import { errorFromResponse } from "../fetcher";

type Props = {
  formData: {
    cancelReasonCode: string;
    changeDate: string;
  };
  subscriptionId: string;
  publicationDomain: string;
};

type CancelResponse = {
  subscriptionId: string;
  pendingCancellationDate: string;
};

type CancelResult = {
  data?: CancelResponse;
  error?: Error;
};

const isValidData = (response: unknown): response is CancelResponse => {
  const r = response as CancelResponse;
  return (
    typeof r.subscriptionId === "string" &&
    typeof r.pendingCancellationDate === "string"
  );
};

export const cancelSubscription = async ({
  formData,
  subscriptionId,
  publicationDomain,
}: Props): Promise<CancelResult> => {
  const url = `/api/vorwerk/subscription/${subscriptionId}/cancel`;

  const fetchOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      cancelReasonCode: formData.cancelReasonCode,
      changeDate: formData.changeDate,
      publicationDomain,
    }),
  };

  try {
    const response = await fetch(url, fetchOptions);

    if (!response.ok) {
      throw await errorFromResponse(response);
    }

    const data = await response.json();
    if (isValidData(data)) {
      return { data };
    }

    throw Error(
      `Cancel subscription returned unexpected data: ${JSON.stringify(data)}`,
    );
  } catch (error) {
    return {
      error: Error(
        `Cancel subscription failed. Error: ${errorToString(error)}`,
      ),
    };
  }
};
