import React from "react";
import { Link, useNavigate, useOutletContext } from "react-router";
import { styled } from "styled-components";

import Address from "../Address";
import { InfoBox } from "../InfoBox";
import { LinkBlock, ExternalLinkBlock } from "../LinkBlock";
import { Sheet } from "../Sheet";
import { BrickButton } from "../BrickButton/brickButton";
import { AppOutlet } from "../AppRoute/appRoute";

import PaymentInfoWrapper from "./paymentInfoWrapper";

import { cancelCancellation } from "@/helpers/cancelCancellation";
import { useTranslate } from "@/i18n";
import { log } from "@/logging/logger";
import { CancelStage } from "@/pages/CancelSubscriptionPage/cancelSubscriptionPage";
import routes from "@/routes/routes";
import colors from "@/theme/colors";
import { dateFormat } from "@/utils/date";
import { useNavigateToReceipt } from "@/pages/ReceiptPage/components/navigateToReceipt";
import { ReceiptType } from "@/pages/ReceiptPage/receiptTypes";
import { AddressChangeSlug } from "@/pages/ChangeAddressPage/changeAddressPage";
import { includesPaperDelivery } from "@/utils/productFeatures";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { useCanCancelCancellation } from "@/fetch/subscription/changes";
import ProductFeatures from "@/components/ProductFeatures";
import { getTestGroupFromLastTwoCharactersInUuid } from "@/utils/testGroup";

type Props = {
  subscription: Subscription;
};

const SubscriptionCard: React.FC<Props> = ({ subscription }: Props) => {
  const { user } = useOutletContext<AppOutlet>();
  const t = useTranslate(messages);
  const navigate = useNavigate();
  const navigateToReceipt = useNavigateToReceipt();

  const { stopChange, error } = useCanCancelCancellation({
    subscriptionId: subscription.id,
  });

  if (error) {
    log.error("useCanCancelCancellation failed. <SubscriptionCard/>", error);
  }
  const hasPaperDelivery = includesPaperDelivery(
    subscription.product.productFeatures,
  );

  const cancelCallbacks = {
    onSuccess: () => {
      navigateToReceipt({ type: ReceiptType.Resubscribe });
    },
    onError: () => {
      navigate(routes.error.path());
    },
  };

  return (
    <Wrapper className="subscriptionCard">
      <Header>
        <LinkBlock
          to={routes.info.path()}
          data-testid="welcome-subscription-link"
          variant="link-heading"
        >
          <Name data-testid="subscription-name">
            {subscription.catalanaTexts.title ?? subscription.name}
          </Name>
        </LinkBlock>
        {subscription.pendingCancellationDate && (
          <CancellationWrapper>
            {t("pendingCancellation", {
              date: dateFormat(subscription.pendingCancellationDate),
            })}
            {stopChange && (
              <BrickButton
                as="button"
                version="outlined"
                width="normal"
                onClick={() =>
                  cancelCancellation({
                    changeId: stopChange.id,
                    subscriptionId: subscription.id,
                    callbacks: cancelCallbacks,
                  })
                }
                label={t("resubscribe")}
              />
            )}
          </CancellationWrapper>
        )}
      </Header>
      <ProductFeatures subscription={subscription} />
      <DeliveryInfo>
        {subscription.isDeliveryPaused && (
          <InfoBox>{t("subscriptionIsPaused")}</InfoBox>
        )}
        {hasPaperDelivery && (
          <AddressSection>
            <AddressListHeader>{t("deliveringTo")}:</AddressListHeader>
            <AddressList>
              {subscription.currentDeliveryAddresses.map((a, i) => (
                <Address key={i} {...a} />
              ))}
            </AddressList>
            <Link to={routes.changes.path(subscription.id)}>
              {t("deliveryChanges")}
            </Link>
          </AddressSection>
        )}
        <PaymentInfoWrapper paymentId={subscription.paymentMethodId} />
      </DeliveryInfo>
      <LinkSection>
        <LinkHeader>{t("addressAndDelivery")}</LinkHeader>
        {hasPaperDelivery && (
          <LinkBlock
            to={routes.changeAddress.path(
              subscription.id,
              AddressChangeSlug.Temporary,
            )}
            data-testid="temporary-address-change-link"
          >
            {t("awayFromHome")}
          </LinkBlock>
        )}
        {hasPaperDelivery && !subscription.isDeliveryPaused && (
          <LinkBlock
            to={routes.temporaryStop.path(subscription.id)}
            data-testid="temporary-stop-link"
          >
            {t("tempStopDelivery")}
          </LinkBlock>
        )}
        <LinkBlock
          to={routes.changeAddress.path(
            subscription.id,
            AddressChangeSlug.Permanent,
          )}
        >
          {t("movingNotice")}
        </LinkBlock>
        {hasPaperDelivery && (
          <LinkBlock to={routes.deliveryComplaint.path(subscription.id)}>
            {t("deliveryComplaints")}
          </LinkBlock>
        )}

        <LinkHeader>{t("payment")}</LinkHeader>
        <LinkBlock to={routes.paymentHistory.path(subscription.id)}>
          {t("paymentHistory")}
        </LinkBlock>
        <LinkBlock
          to={routes.payment.path(subscription.id)}
          data-testid="changePaymentMethod"
        >
          {t("changePaymentMethod")}
        </LinkBlock>

        <LinkHeader>{t("other")}</LinkHeader>
        <ExternalLinkBlock
          href={`${window.location.origin}/tilbud`}
          icon={false}
        >
          {t("changeSubscription")}
        </ExternalLinkBlock>
        {getTestGroupFromLastTwoCharactersInUuid(user.uuid) === "A" && (
          <ExternalLinkBlock
            href={`${window.location.origin}/tilbud/guide`}
            icon={false}
          >
            {t("tryOfferGuide")}
          </ExternalLinkBlock>
        )}
        <ExternalLinkBlock href="https://www.amedia.no/personvern/abonnementsvilkar/">
          {t("subscriptionConditions")}
        </ExternalLinkBlock>
        {subscription.canCancel && (
          <LinkBlock
            to={routes.cancelSubscription.path(
              subscription.id,
              CancelStage.INFO,
            )}
          >
            {t("cancelSubscription")}
          </LinkBlock>
        )}
        {!subscription.canCancel && !subscription.pendingCancellationDate && (
          <LinkBlock
            to={routes.cancelSubscription.path(
              subscription.id,
              CancelStage.UNAVAILABLE,
            )}
          >
            {t("cancelSubscription")}
          </LinkBlock>
        )}
      </LinkSection>
    </Wrapper>
  );
};

const Wrapper = styled(Sheet)`
  padding: 0;
`;

const Header = styled.div`
  padding: 25px;
  background-color: ${colors.contrastBeigeDark};
  color: ${colors.white};
`;

const Name = styled.h3`
  margin: 0;
  text-transform: uppercase;
`;

const DeliveryInfo = styled.div`
  padding: 25px;
`;

const AddressSection = styled.div`
  &:not(:first-child) {
    margin-top: 25px;
  }
`;

const AddressList = styled.div`
  & > * {
    margin-bottom: 15px;
  }
`;

const AddressListHeader = styled.div`
  font-size: small;
  font-weight: bold;
  margin-bottom: 7px;
`;
const LinkSection = styled.div`
  padding: 0;
`;

const LinkHeader = styled.div`
  background-color: ${colors.contrastBeigeDark};
  padding: 5px 25px;
  color: ${colors.white};
  text-transform: uppercase;
  font-size: small;
`;

const CancellationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const messages = {
  deliveryAddressesError: {
    nb: "Greide ikke finne leveringsadressen din",
    nn: "Greidde ikkje finne leveringsadressa di",
  },
  deliveringTo: {
    nb: "Avisen leveres til",
    nn: "Avisa vert levert til",
  },
  deliveryChanges: {
    nb: "Se status på leveringsendringer",
    nn: "Sjå status på leveringsendringar",
  },
  nextInvoice: {
    nb: "Neste betaling: {{date}}",
    nn: "Neste betaling: {{date}}",
  },
  addressAndDelivery: {
    nb: "Adresse og levering",
    nn: "Adresse og levering",
  },
  awayFromHome: {
    nb: "Jeg skal reise bort",
    nn: "Eg skal reise bort",
  },
  movingNotice: {
    nb: "Meld permanent adresseendring",
    nn: "Meld permanent adresseendring",
  },
  deliveryComplaints: {
    nb: "Klage på levering",
    nn: "Klage på levering",
  },
  changePaymentMethod: {
    nb: "Endre betalingsmetode",
    nn: "Endre betalingsmetode",
  },
  changeSubscription: {
    nb: "Bytt abonnement",
    nn: "Bytt abonnement",
  },
  subscriptionConditions: {
    nb: "Les avtalevilkårene",
    nn: "Les avtalevilkåra",
  },
  cancelSubscription: {
    nb: "Si opp abonnementet",
    nn: "Sei opp abonnementet",
  },
  resubscribe: {
    nb: "Angre oppsigelsen",
    nn: "Angre oppsigelsen",
  },
  tryOfferGuide: {
    nb: "Prøv abonnementsguiden",
    nn: "Prøv abonnementsguiden",
  },
  payment: {
    nb: "Betaling",
    nn: "Betaling",
  },
  paymentHistory: {
    nb: "Betalingshistorikk",
    nn: "Betalingshistorikk",
  },
  other: {
    nb: "Annet",
    nn: "Anna",
  },
  pendingCancellation: {
    nb: "Abonnementet avsluttes den {{date}}.",
    nn: "Abonnementet vert avslutta den {{date}}.",
  },
  tempStopDelivery: {
    nb: "Stopp levering midlertidig",
    nn: "Mellombels stopp levering",
  },
  subscriptionChangeOverview: {
    nb: "Se leveringsendringer",
    nn: "Sjå endringar i levering",
  },
  subscriptionIsPaused: {
    nb: "Levering av papiravis på dette abonnementet er satt på pause.",
    nn: "Levering av papiravis på dette abonnementet er satt på pause.",
  },
};

export default SubscriptionCard;
