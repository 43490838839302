import React, { useContext, useEffect, useState } from "react";

import { Sheet } from "@/components/Sheet";
import { useTranslate } from "@/i18n";
import AppContext from "@/contexts/appContext";
import { BrickButton } from "@/components/BrickButton/brickButton";
import Adplogger2Wrapper from "@/components/Adplogger2/Adplogger2Wrapper";
import {
  activateSubscription,
  ActivationStatus,
} from "@/services/shamo/activate-subscription";
import { FailureBox, InfoBox, SuccessBox } from "@/components/InfoBox";
import CustomerServiceInfo from "@/components/CustomerServiceInfo";

const ActivateSubscription: React.FC = () => {
  const [activationStatus, setActivationStatus] = useState<ActivationStatus>({
    status: "uninitialized",
  });
  const [isLoading, setIsLoading] = useState(false);
  const t = useTranslate(messages);
  const { site } = useContext(AppContext);

  const tryActivate = async (publicationDomain: string) => {
    setActivationStatus({ status: "uninitialized" });
    setIsLoading(true);
    const activationResult = await activateSubscription(publicationDomain);
    setIsLoading(false);
    setActivationStatus(activationResult);
  };

  // reloads the page after a short delay, user should get their subscription rendered
  useEffect(() => {
    if (
      activationStatus.status === "success" &&
      activationStatus.detail === "subscription_activated"
    ) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [activationStatus]);

  return (
    <Adplogger2Wrapper
      adpType="activate button"
      name="activate-subscription(/abo)"
    >
      <Sheet $primary>
        <h2>{t("title", { publicationName: site.name.full })}</h2>
        <p>{t("description", { publicationName: site.name.full })}</p>
        <BrickButton
          as="button"
          label={t("cta")}
          version="primary"
          onClick={() => tryActivate(site.domain)}
          style={{ marginBottom: 20 }}
          adpClickLabel="activate-subscription"
          adpClickValue="yes"
          disabled={isLoading}
        />
        {isLoading && <InfoBox className="small">{t("loading")} </InfoBox>}
        {activationStatus.status === "success" &&
          activationStatus.detail === "subscription_activated" && (
            <SuccessBox className="small">
              {t("subscriptionActivated")}
            </SuccessBox>
          )}
        {activationStatus.status === "failure" && (
          <FailureBox>
            <p>{t("failure")}</p>
            <CustomerServiceInfo />
          </FailureBox>
        )}
      </Sheet>
    </Adplogger2Wrapper>
  );
};

export default ActivateSubscription;

const messages = {
  title: {
    nb: "Har du allerede abonnement på {{publicationName}}?",
    nn: "Har du allereie abonnement på {{publicationName}}?",
  },
  description: {
    nb: "Aktiver abonnementet med din aID-bruker for å få full tilgang på {{publicationName}}. Det tar bare ett minutt.",
    nn: "Aktiver abonnementet med din aID-brukar for å få full tilgang på {{publicationName}}. Det tek berre eit minutt.",
  },
  cta: {
    nb: "Aktiver tilgangen her",
    nn: "Aktiver tilgangen her",
  },
  loading: {
    nb: "Prøver å aktivere abonnement...",
    nn: "Prøver å aktivera abonnement...",
  },
  subscriptionActivated: {
    nb: "Aktivering gjennomført",
    nn: "Aktivering gjennomført",
  },
  failure: {
    nb: "Greide ikke aktivere abonnement, kontakt kundeservice",
    nn: "Greidde ikkje aktivera abonnement, kontakt kundeservice",
  },
};
