import React, { useContext } from "react";
import { Navigate, useParams } from "react-router";

import { NavigateToErrorPage } from "../ErrorPage/errorPage";

import AutoInvoiceCallback from "./components/autoInvoiceCallback";
import NetsEasyReplaceCard from "./components/replaceCardCallback";

import AppContext from "@/contexts/appContext";
import { log } from "@/logging/logger";
import routes from "@/routes/routes";
import { pageTitle } from "@/utils/page";
import { CallbackType } from "@/fetch/payment/helpers";

const CallbackPage: React.FC = () => {
  const context = useContext(AppContext);
  const { locale, name } = context.site;
  const { paymentType } = useParams();

  pageTitle.set(pageTitle.create(name.short, routes.callback.title[locale]));

  if (!paymentType) {
    log.error("Callback page without paymentType");
    return <Navigate to={routes.error.path()} />;
  }

  const callbacks: Record<string, React.ReactElement> = {
    [CallbackType.AUTO_INVOICE]: <AutoInvoiceCallback />,
    [CallbackType.CREDIT_CARD]: <NetsEasyReplaceCard />,
  };

  if (!callbacks[paymentType]) {
    log.error(`Fant ikke betalingsmetode ${paymentType}`);
    return <NavigateToErrorPage errorCode="paymentType" />;
  }
  return callbacks[paymentType];
};

export default CallbackPage;
