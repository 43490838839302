import { HttpError } from "./errors";

export const errorFromResponse = async (res: Response, msg?: string) => {
  try {
    const data = await res.json();

    if (data.error) {
      return Error(`Response not OK, error in Data`, {
        cause: data.error,
      });
    }
  } catch (err) {
    return new Error(`Response not OK. Response: ${JSON.stringify(res)}`, {
      cause: err,
    });
  }

  const error = new HttpError(msg || "Fetcher failed with unknown error.");
  error.status = res.status;
  return error;
};

export const fetcher = async ([...args]) => {
  const url = args[0];
  const options = args[1];

  if (url) {
    const res = await fetch(url, options);

    if (!res.ok) {
      throw await errorFromResponse(res);
    }

    const resClone = res.clone();
    try {
      return res.json();
    } catch (err) {
      throw new Error(
        `Response is not valid JSON. response.text(): [${await resClone.text()}]`,
        { cause: err },
      );
    }
  }
};
