import { errorFromResponse } from "../fetcher";
import { errorToString } from "../errors";

type Props = {
  changeId: string;
  subscriptionId: string;
};

export const deleteChange = async ({
  changeId,
  subscriptionId,
}: Props): Promise<{ status: "SUCCESS" | "ERROR"; error?: Error }> => {
  const url = `/api/vorwerk/subscription/${subscriptionId}/change/${changeId}`;

  try {
    const response = await fetch(url, { method: "DELETE" });

    if (!response.ok) {
      throw await errorFromResponse(response);
    }

    return { status: "SUCCESS" };
  } catch (error) {
    return {
      status: "ERROR",
      error: Error(
        `Delete change failed. ChangeId: ${changeId}. Error: ${errorToString(
          error,
        )}`,
      ),
    };
  }
};
