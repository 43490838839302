import React, { useCallback } from "react";
import dayjs from "dayjs";
import { styled } from "styled-components";
import { useNavigate } from "react-router";

import AddressFieldset, { InputAddress } from "./addressFieldset";
import { addressErrorMessages, validateAddress } from "./addressValidation";

import { Sheet } from "@/components/Sheet";
import DatePicker from "@/components/DatePicker";
import { InputField } from "@/components//InputField";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { FormSubmit, useForm } from "@/utils/form";
import { TranslationFunction, useTranslate } from "@/i18n";
import { log } from "@/logging/logger";
import routes from "@/routes/routes";
import { useNavigateToReceipt } from "@/pages/ReceiptPage/components/navigateToReceipt";
import { ReceiptType } from "@/pages/ReceiptPage/receiptTypes";
import { registerAddressChange } from "@/fetch/address/registerAddressChange";
import { BrickButton } from "@/components/BrickButton/brickButton";
import { ButtonContainer } from "@/components/ButtonContainer";

type Props = { subscription: Subscription };

type PermanentMove = InputAddress & {
  fromDate: string;
};

const validateInputAddress = (
  formValues: InputAddress,
  t: TranslationFunction<typeof addressErrorMessages>,
): Partial<InputAddress> => {
  const errors: Partial<InputAddress> = {};
  const addressErrors = validateAddress(formValues);

  addressErrors.forEach((err) => {
    errors[err[0]] = t(err[1]);
  });

  return errors;
};

const Permanent: React.FC<Props> = ({ subscription }) => {
  const t = useTranslate(messages);
  const navigate = useNavigate();
  const navigateToReceipt = useNavigateToReceipt();

  const submit: FormSubmit<PermanentMove> = useCallback(
    async ({ fromDate, ...address }) => {
      const response = await registerAddressChange({
        subscriptionId: subscription.id,
        addressChangeRequest: {
          address,
          fromDate,
          type: "permanent",
        },
      });

      if (response.status === "success") {
        return navigateToReceipt({
          type: ReceiptType.PermanentMove,
          address,
          fromDate,
        });
      }
      log.error(`Permanent address change failed. ${response.error}`);
      return navigate(routes.error.path());
    },
    [subscription.id, navigate, navigateToReceipt],
  );

  const initialFormValues: PermanentMove = {
    streetName: "",
    streetNumber: "",
    entrance: "",
    floor: "",
    apartment: "",
    postalCode: "",
    city: "",
    careOf: "",
    fromDate: dayjs(subscription.earliestDeliveryChange).format("YYYY-MM-DD"),
  };
  const {
    propsForField,
    values,
    setValue,
    errors,
    submitForm,
    isSubmitting,
    firstInvalidInput,
  } = useForm(
    initialFormValues,
    addressErrorMessages,
    validateInputAddress,
    submit,
  );

  return (
    <Sheet $primary>
      <form onSubmit={submitForm} noValidate>
        <AddressFieldset
          propsForField={propsForField}
          errors={errors}
          setValue={setValue}
          firstInvalidInput={firstInvalidInput}
        />
        <DateWrapper>
          <InputField description={t("when")} error={errors.fromDate}>
            <DatePicker
              minDate={subscription.earliestDeliveryChange}
              selectedDate={values.fromDate}
              onChange={(v) => setValue("fromDate", v)}
              $error={!!errors.fromDate}
              $large={true}
            />
          </InputField>
        </DateWrapper>
        <ButtonContainer>
          <BrickButton
            as="button"
            label={t("submit")}
            version="primary"
            disabled={isSubmitting}
            width="normal"
            type="submit"
          />
          <Info>
            <strong>{t("obs")}</strong> {t("info")}
          </Info>
        </ButtonContainer>
      </form>
    </Sheet>
  );
};

const DateWrapper = styled.div`
  margin-top: 20px;
`;

const Info = styled.p`
  margin: 0;
  flex: 1;
  align-self: center;
`;

export default Permanent;

const messages = {
  submit: {
    nb: "Send inn",
    nn: "Send inn",
  },
  when: {
    nb: "Når skal du flytte?",
    nn: "Når skal du flytte?",
  },
  obs: {
    nb: "OBS!",
    nn: "OBS!",
  },
  info: {
    nb: "For at avisen skal komme frem, må adressen også være registrert hos posten.no.",
    nn: "For at avisa skal komme fram, må adressa også vere registrert hos posten.no.",
  },
};
