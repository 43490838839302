import React from "react";
import { Navigate } from "react-router";

import CallbackNavigate, { isValidStatus } from "./callbackNavigate";

import routes from "@/routes/routes";
import { log } from "@/logging/logger";
import { UpdatePaymentStatus } from "@/fetch/payment/helpers";

const autoInvoiceStatus: Record<string, UpdatePaymentStatus> = {
  ok: UpdatePaymentStatus.SUCCESS,
  error: UpdatePaymentStatus.ERROR,
  cancel: UpdatePaymentStatus.ERROR,
  default: UpdatePaymentStatus.ERROR,
};

const AutoInvoiceCallback: React.FC = () => {
  const params = new URLSearchParams(window.location.search);
  const statusParam = params.get("status")?.toLowerCase();

  const status = statusParam
    ? autoInvoiceStatus[statusParam]
    : autoInvoiceStatus.default;

  if (!status || !isValidStatus(status)) {
    const error = `AvtalegiroCallback, status: ${statusParam} is missing or invalid`;
    log.error(error);
    return <Navigate to={routes.error.path()} replace={true} />;
  }

  return <CallbackNavigate type="autoInvoice" status={status} />;
};

export default AutoInvoiceCallback;
