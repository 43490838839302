import React, { useContext } from "react";
import { useLocation, Navigate } from "react-router";

import ErrorBlock from "@/components/ErrorBlock";
import AppContext from "@/contexts/appContext";
import { useTranslate } from "@/i18n";
import routes from "@/routes/routes";
import BreadCrumbs from "@/components/Breadcrumbs";

type ErrorCode = keyof typeof errors;

export const NavigateToErrorPage: React.FC<{
  errorCode?: ErrorCode;
}> = ({ errorCode }) => {
  history.replaceState(null, "", routes.myPage.path());
  return <Navigate to={routes.error.path()} state={{ errorCode }} />;
};

const ErrorPage: React.FC = () => {
  const { site } = useContext(AppContext);
  const t = useTranslate({ ...messages, ...errors });
  const title = routes.error.title[site.locale];
  const { state } = useLocation();

  return (
    <>
      <BreadCrumbs>{title}</BreadCrumbs>
      <ErrorBlock title={t("title")}>
        {errors[state?.errorCode as ErrorCode] && <p>{t(state?.errorCode)}</p>}
      </ErrorBlock>
    </>
  );
};

export default ErrorPage;

const errors = {
  address: {
    nb: "Teknisk feil ved uthenting av adresseendringer",
    nn: "Teknisk feil ved uthenting av adresseendringar",
  },
  paymentType: {
    nb: "Fant ikke betalingsmetoden",
    nn: "Fann ikkje betalingsmetoden",
  },
  paymentHistory: {
    nb: "Greide ikke laste inn betalingshistorikken",
    nn: "Greidde ikkje lasta inn betalingshistorikken",
  },
};

const messages = {
  title: {
    nb: "Beklager, noe gikk galt",
    nn: "Beklagar, noko gjekk gale",
  },
};
