import React, { useContext } from "react";
import { Navigate, useOutletContext } from "react-router";

import Complaint from "./components/complaint";

import PageHeader from "@/components/PageHeader";
import { pageTitle } from "@/utils/page";
import AppContext from "@/contexts/appContext";
import routes from "@/routes/routes";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute/subscriptionRoute";
import { log } from "@/logging/logger";
import { includesPaperDelivery } from "@/utils/productFeatures";
import SubscriptionTypeNotSupported from "@/components/SubscriptionTypeNotSupported/subscriptionTypeNotSupported";
import Spinner from "@/components/Spinner";
import BreadCrumbs from "@/components/Breadcrumbs";
import { useComplaintCodesFetcher } from "@/fetch/subscription/complaint";

const DeliveryComplaintPage: React.FC = () => {
  const context = useContext(AppContext);
  const { locale, name } = context.site;
  const { subscription } = useOutletContext<SubscriptionOutlet>();
  const title = routes.deliveryComplaint.title[locale];

  pageTitle.set(pageTitle.create(name.short, title));

  const { complaintCodes, error, isLoading } = useComplaintCodesFetcher({
    subscriptionId: subscription.id,
  });

  if (!includesPaperDelivery(subscription.product.productFeatures)) {
    return <SubscriptionTypeNotSupported subscription={subscription} />;
  }

  if (error || (!isLoading && complaintCodes?.length === 0)) {
    const err = error || new Error("No complaint codes found");
    log.error("Failed to fetch complaint codes", err);
    return <Navigate to={routes.error.path()} />;
  }

  return complaintCodes.length > 0 ? (
    <>
      <BreadCrumbs>{title}</BreadCrumbs>
      <PageHeader>{title}</PageHeader>
      <Complaint
        subscription={subscription}
        complaintOptions={complaintCodes}
      />
    </>
  ) : (
    <Spinner />
  );
};

export default DeliveryComplaintPage;
