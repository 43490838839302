import React, { useContext } from "react";
import { styled } from "styled-components";
import { useOutletContext } from "react-router";

import WinbackOfferingInner from "./winbackOfferingInner";

import AppContext from "@/contexts/appContext";
import { array } from "@/utils/array";
import Adplogger2Wrapper from "@/components/Adplogger2/Adplogger2Wrapper";
import { log } from "@/logging/logger";
import { Sheet } from "@/components/Sheet";
import colors from "@/theme/colors";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute";
import { useOfferingFetcher } from "@/fetch/useOfferingFetcher";

const WinbackSubscriptionOffer: React.FC = () => {
  const { subscription } = useOutletContext<SubscriptionOutlet>();
  const { locale } = useContext(AppContext).site;
  const channel = "product_change";

  const { offerings, error } = useOfferingFetcher(
    {
      channel,
      filterBy: [
        {
          filter: "user_product_features",
          options: { predicate: "downgrade" },
        },
      ],
      orderBy: [{ value: "product_features", order: "descending" }],
    },
    locale,
  );

  if (error) {
    log.error("Failed to fetch winback offering", error);
    return null;
  }

  if (offerings.length === 0) {
    return null;
  }

  const cheapestWinbackOffering = offerings.reduce((prev, curr) =>
    array.last(prev.commercialTerms).price.amount <
    array.last(curr.commercialTerms).price.amount
      ? prev
      : curr,
  );

  const { price, duration } = array.last(
    cheapestWinbackOffering.commercialTerms,
  );

  const durationLength = duration.length ?? 1;
  const savings = Math.round(
    (subscription.currentRate.rate.totalAmount - price.amount) / durationLength,
  );

  const adploggerData = {
    currentSubscriptionId: subscription.id,
    winbackType: "downgrade",
  };

  const winbackUrl = new URL(window.location.origin);
  winbackUrl.pathname = "/tilbud";
  winbackUrl.searchParams.set("channel", channel);
  winbackUrl.searchParams.set("source", "abo_winback");
  winbackUrl.searchParams.set("user_product_features", "downgrade");

  return (
    <Adplogger2Wrapper
      adpType="View"
      name="Oppsigelse_winback"
      data={adploggerData}
    >
      <Component>
        <WinbackOfferingInner winbackUrl={winbackUrl} savings={savings} />
      </Component>
    </Adplogger2Wrapper>
  );
};

const Component = styled(Sheet)`
  margin-top: 30px;
  background-color: ${(props) =>
    props.theme.primaryColor ?? colors.almostBlack};
  color: ${colors.almostWhite};
`;

export default WinbackSubscriptionOffer;
