import { AddressChangeRequest } from "@amedia/node-clients-abo";

import { errorFromResponse } from "../fetcher";
import { errorToString } from "../errors";

export const registerAddressChange = async ({
  subscriptionId,
  addressChangeRequest,
}: {
  subscriptionId: string;
  addressChangeRequest: AddressChangeRequest;
}): Promise<{ status: "success" | "error"; error?: string }> => {
  const url = `/api/vorwerk/subscription/${subscriptionId}/address/register`;

  const fetchOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      addressChangeRequest,
    }),
  };

  try {
    const response = await fetch(url, fetchOptions);

    if (!response.ok) {
      throw await errorFromResponse(response);
    }

    const data = (await response.json()) as { status: string };

    if (data.status === "success") {
      return { status: data.status };
    } else {
      throw new Error(
        `Failed to register address change. Unexpected status: ${data.status}`,
      );
    }
  } catch (error) {
    return { status: "error", error: errorToString(error) };
  }
};
