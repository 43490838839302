import React, { forwardRef, PropsWithChildren } from "react";
import { styled } from "styled-components";

import InputError from "../InputError";

import colors from "@/theme/colors";

const InputSection = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const InputDescription = styled.div`
  margin-bottom: 7px;
  &.required:after {
    content: " *";
    color: ${colors.errorRedDark};
  }
`;

interface Props {
  error?: boolean;
}

const Input = styled.input<Props>`
  font: inherit;
  padding: 0.7em;
  border: 2px solid
    ${({ error }) => (error ? colors.errorRedDark : colors.inactiveGray)};
  width: 100%;

  &:focus {
    border-color: ${colors.almostBlack};
    outline: none;
  }
`;

type InputFieldProps = PropsWithChildren & {
  description?: string | React.ReactElement;
  error?: string;
  className?: string;
  required?: boolean;
};

export const InputField: React.FC<InputFieldProps> = ({
  description,
  error,
  className,
  required,
  children,
}) => (
  <InputSection className={className}>
    {description && (
      <InputDescription className={required ? "required" : ""}>
        {description}
      </InputDescription>
    )}
    {children}
    <InputError
      arrow={true}
      error={error}
      id={description ? `${description}-id` : ""}
    />
  </InputSection>
);

interface TextInputFieldProps {
  description: string;
  error?: string;
  showErrorText?: boolean;
  className?: string;
  required?: boolean;
}

type InputProps = React.ComponentProps<typeof Input>;

type RestInputProps = Pick<
  InputProps,
  Exclude<keyof InputProps, keyof TextInputFieldProps>
>;

type TextInputFieldType = Partial<RestInputProps> & TextInputFieldProps;

// React.forwardRef<ReferenceType, PropsType>
const TextInputField = forwardRef<HTMLInputElement, TextInputFieldType>(
  (
    {
      description,
      error,
      showErrorText = true,
      className,
      required = false,
      ...rest
    },
    ref,
  ) => (
    <InputField
      description={description}
      error={showErrorText ? error : undefined}
      className={className}
      required={required}
    >
      <Input
        aria-invalid={required && !!error}
        aria-describedby={`${description}-id`}
        error={!!error}
        type="text"
        required={required}
        {...rest}
        ref={ref}
      />
    </InputField>
  ),
);

TextInputField.displayName = "TextInputField";
export default TextInputField;
