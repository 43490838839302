import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

import { TranslationFunction, TranslationMessages, useTranslate } from "@/i18n";

export type FormErrors<T extends object> = Partial<
  Record<keyof T | "global", string | undefined>
>;
type Validator<T extends object, K extends TranslationMessages> = (
  values: T,
  translator: TranslationFunction<K>,
) => FormErrors<T>;
type Edited<T extends object> = Record<keyof T, boolean>;

type HandleChangeEvent = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
type HandleChange = (e: HandleChangeEvent) => void;
type HandleBlurEvent = React.FocusEvent<HTMLInputElement | HTMLSelectElement>;
type HandleBlur = (e: HandleBlurEvent) => void;

type SetErrors<T extends object> = (errors: FormErrors<T>) => void;

export type FormSubmit<T extends object> = (
  values: T,
  done: () => void,
  setErrors: SetErrors<T>,
) => void | Promise<void>;

export type FormProps<T extends object> = {
  values: T;
  errors: FormErrors<T>;
  onBlur: HandleBlur;
  setErrors: SetErrors<T>;
  isSubmitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  submitForm: (e?: React.SyntheticEvent<EventTarget, Event>) => void;
  handleChange: HandleChange;
  setValue: <K extends keyof T>(field: K, value: T[K]) => void;
  propsForField: <K extends keyof T>(
    field: K,
  ) => {
    name: K;
    value: T[K];
    error: FormErrors<T>[K];
    onChange: HandleChange;
    onBlur: HandleBlur;
  };
  firstInvalidInput: string;
};

const trimObjectValues = <T extends object>(obj: T): T =>
  Object.keys(obj).reduce<T>(
    (prev, current) => {
      const val = (obj as Record<string, unknown>)[current];
      (prev as Record<string, unknown>)[current] =
        typeof val === "string" || val instanceof String ? val.trim() : val;
      return prev;
    },
    { ...obj },
  );

type Actions<T extends object> =
  | {
      type: "SET_VALUE";
      field: keyof T;
      value: T[keyof T];
    }
  | {
      type: "BLUR";
      field: keyof T;
    }
  | {
      type: "VALIDATE_ALL";
    };

type FormState<T extends object> = {
  values: T;
  edited: Edited<T>;
};

const formReducer = <T extends object>(
  state: FormState<T>,
  action: Actions<T>,
): FormState<T> => {
  switch (action.type) {
    case "SET_VALUE": {
      const { field, value } = action;
      return {
        ...state,
        values: { ...state.values, [field]: value },
      };
    }
    case "BLUR": {
      const { field } = action;
      return {
        ...state,
        edited: { ...state.edited, [field]: true },
      };
    }
    case "VALIDATE_ALL": {
      return {
        ...state,
        edited: Object.keys(state.values).reduce<Edited<T>>((prev, curr) => {
          (prev as Record<string, unknown>)[curr] = true;
          return prev;
        }, {} as Edited<T>),
      };
    }
    default:
      throw new Error(`Unknown form action: ${JSON.stringify(action)}`);
  }
};

const initReducer = <T extends object>(initialValues: T): FormState<T> => ({
  values: initialValues,
  edited: Object.keys(initialValues).reduce<Edited<T>>((prev, curr) => {
    (prev as Record<string, unknown>)[curr] = false;
    return prev;
  }, {} as Edited<T>),
});

export const useForm = <T extends object, K extends TranslationMessages>(
  initialValues: T,
  messages: K,
  validator: Validator<T, K>,
  onSubmit: FormSubmit<T>,
): FormProps<T> => {
  const [rawErrors, setRawErrors] = useState<FormErrors<T>>({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [firstInvalidInput, setFirstInvalidInput] = useState<string>("");

  const [{ values, edited }, dispatch] = useReducer(
    formReducer,
    initReducer(initialValues),
  );

  const translator = useTranslate(messages);

  const validateForm = useCallback(
    (newValues: T, submitBtnClicked = false) => {
      const newErrors = validator(trimObjectValues(newValues), translator);
      setRawErrors(newErrors);

      const firstError = Object.keys(newErrors)[0];
      setFirstInvalidInput("");
      if (submitBtnClicked && firstError) {
        setFirstInvalidInput(firstError);
      }
      return !firstError;
    },
    [translator, validator],
  );

  const doneSubmitting = useCallback(() => {
    setSubmitting(false);
  }, [setSubmitting]);

  const setErrors = useCallback(
    (errors: FormErrors<T>) => {
      setRawErrors(errors);
      dispatch({ type: "VALIDATE_ALL" });
      setSubmitting(false);
    },
    [setRawErrors],
  );

  const submitForm = useCallback(
    (e?: React.SyntheticEvent<EventTarget>) => {
      if (e) {
        e.preventDefault();
      }
      if (isSubmitting) {
        return;
      }
      setSubmitting(true);
      const validateSuccess = validateForm(values, true);
      if (validateSuccess) {
        onSubmit(trimObjectValues(values), doneSubmitting, setErrors);
      } else {
        dispatch({ type: "VALIDATE_ALL" });
        setSubmitting(false);
      }
    },
    [isSubmitting, validateForm, values, onSubmit, doneSubmitting, setErrors],
  );

  const setValue = useCallback(
    <Key extends keyof T>(field: Key, value: T[Key]) => {
      dispatch({ type: "SET_VALUE", field, value });
    },
    [dispatch],
  );

  useEffect(() => {
    validateForm(values);
  }, [validateForm, values]);

  const handleChange = useCallback(
    (e: HandleChangeEvent) => {
      const field = e.target.name as keyof T;
      const value = e.target.value as unknown as T[keyof T];
      setValue(field, value);
    },
    [setValue],
  );

  const handleBlur = useCallback(
    (e: HandleBlurEvent) => {
      const field = e.target.name as keyof T;
      dispatch({ type: "BLUR", field });
    },
    [dispatch],
  );

  const errors = useMemo(
    () =>
      Object.keys(rawErrors).reduce<FormErrors<T>>((prev, key) => {
        const editedObj: Record<string, unknown> = edited;
        const rawErrorsObj: Record<string, unknown> = rawErrors;
        const prevObj: Record<string, unknown> = prev;

        if (
          (editedObj[key] || key === "global") &&
          rawErrorsObj[key] !== undefined
        ) {
          prevObj[key] = rawErrorsObj[key];
        }
        return prev;
      }, {}),
    [rawErrors, edited],
  );

  const propsForField = useCallback(
    <Key extends keyof T>(field: Key) => ({
      name: field,
      value: values[field],
      error: edited[field] ? errors[field] : undefined,
      onChange: handleChange,
      onBlur: handleBlur,
    }),
    [values, edited, errors, handleChange, handleBlur],
  );

  return {
    values,
    errors,
    setErrors,
    isSubmitting,
    setSubmitting,
    submitForm,
    handleChange,
    setValue,
    propsForField,
    onBlur: handleBlur,
    firstInvalidInput,
  };
};
