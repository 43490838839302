import "@amedia/brick-button";
import { Design } from "@amedia/brick-tokens";
import { MouseEventHandler } from "react";
import { styled } from "styled-components";

export interface BrickButtonProps {
  as: "a" | "button" | "span" | "div";
  label: string;
  version: "primary" | "secondary" | "outlined";
  href?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  id?: string;
  width?: "normal" | "full";
  size?: "slim" | "normal" | "small";
  ariaControls?: string;
  ariaExpanded?: boolean;
  adpClickLabel?: string;
  adpClickValue?: string;
  icon?: {
    id: string;
    text?: string;
    theme?: Design;
    position?: "right" | "top";
  };
  testId?: string;
  style?: React.CSSProperties;
  customBgColor?: string;
  disabled?: boolean;
  type?: string;
  key?: string | number;
}

export const BrickButton = (props: BrickButtonProps) => {
  return (
    <ButtonContainer
      className={props.disabled ? "disabled" : ""}
      $customBgColor={props.customBgColor}
      style={props.style}
    >
      <brick-button-v7
        onClick={props.as === "button" ? props.onClick : undefined}
        id={props.as === "button" ? props.id : ""}
        class={`width-${props.width ?? "normal"} size-${
          props.size ?? "normal"
        }`}
        data-label={props.label}
        data-version={props.version ?? "primary"}
        data-size={props.size ?? "medium"}
        data-linkto={props.as === "a" ? props.href : ""}
        data-adp-clicklabel={props.adpClickLabel}
        data-adp-clickvalue={props.adpClickValue}
        data-iconid={props.icon?.id}
        data-icontext={props.icon?.text}
        data-icontheme={props.icon?.theme}
        data-iconplacement={props.icon?.position}
        data-testid={props.testId}
        data-as={props.as}
        aria-controls={props.ariaControls}
        aria-expanded={props.ariaExpanded}
        disabled={props.disabled}
        type={props.type}
      ></brick-button-v7>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div<{
  $customBgColor?: string;
}>`
  --brick-colors-buttonPrimaryBg: ${(props) => props.$customBgColor};
  --brick-colors-buttonSecondaryBg: ${(props) => props.$customBgColor};

  &.disabled {
    pointer-events: none;

    button {
      background: gray;
    }
  }
`;

export default BrickButton;
