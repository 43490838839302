import React, { useContext } from "react";
import { useOutletContext } from "react-router";

import PaymentOverview from "./components/paymentOverview";

import PageHeader from "@/components/PageHeader";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute/subscriptionRoute";
import AppContext from "@/contexts/appContext";
import routes from "@/routes/routes";
import { pageTitle } from "@/utils/page";
import BreadCrumbs from "@/components/Breadcrumbs";

const ChangePaymentPage: React.FC = () => {
  const context = useContext(AppContext);
  const { locale, name } = context.site;
  const { subscription } = useOutletContext<SubscriptionOutlet>();
  const title = routes.payment.title[locale];

  pageTitle.set(pageTitle.create(name.short, title));

  return (
    <>
      <BreadCrumbs>{title}</BreadCrumbs>
      <PageHeader>{title}</PageHeader>
      <PaymentOverview subscription={subscription} />
    </>
  );
};
export default ChangePaymentPage;
